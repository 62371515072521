.full-logo {
    width: 100%;
    height: auto;
}

.pageHeader {
    background-color: $primary;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 1em;
}

.emptyImgContainer {
    border: 1px dashed $gray;
    min-height: 210px;
}

.addImg {
    height: 93%;
    min-height: 300px;
    border: 1px dashed $gray;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.addImg img {
    width: auto;
    max-height: 220px;
}

.imgCloseBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 0;
}

.dashboard-card:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: ease-in-out;
    z-index: 999;
}

.workLogoImg {
    max-width: 200px;
    height: auto;
}
